import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "日本 CTO 協会 DX Criteria WG 所属の ahomu さんをお招きして Web フロントエンド版 DX Criteria 勉強会を開催しました",
  "date": "2024-11-29T00:45:50.000Z",
  "slug": "entry/2024/11/29/094550",
  "tags": [],
  "hero": "./thumbnail_20241129.png",
  "heroAlt": "日本 CTO 協会 DX Criteria WG 所属の ahomu さんをお招きして Web フロントエンド版 DX Criteria 勉強会を開催しました"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`皆さん、こんにちは! エンジニア / エンジニア・デザイナー採用担当の平木です。
弊社では社内のエンジニアが定期・不定期で勉強会を開催しています。この勉強会は、プロダクト開発の話はもちろんですが、各技術の最新情報の共有などを、事業部に留まらず全社的に行う会となっています。`}</p>
    <p>{`11/15(金)にも社内勉強会を開催したのですが、題材を`}<a parentName="p" {...{
        "href": "https://dxcriteria.cto-a.org/frontend"
      }}>{`Web フロントエンド版 DX Criteria`}</a>{`についてとしました。そこで作成者の 1 人である日本 CTO 協会 DX Criteria WG の佐藤 歩(`}<a parentName="p" {...{
        "href": "https://x.com/ahomu"
      }}>{`@ahomu`}</a>{`)さん(以下 ahomu さん)を講師としてお招きして講演をしていただきましたので、そのレポートとなります。`}</p>
    <h1>{`今回の勉強会開催の背景`}</h1>
    <p>{`今年 4 月に Web フロントエンド版 DX Criteria が`}<a parentName="p" {...{
        "href": "https://prtimes.jp/main/html/rd/p/000000024.000081310.html"
      }}>{`発表`}</a>{`されたのを見て、内容的にメドレーでも参考にできる部分が非常に多いと思いました。`}</p>
    <p>{`作成者の 1 人である ahomu さんは筆者の前職同僚だったということもあり、勉強会講師を打診して快諾いただいたため、開催の運びとなりました。`}</p>
    <h1>{`勉強会について`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3a49e486a8225c616fff2c3c95a2ec4e/71254/2024-11-15_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABYxPhFEnh/8QAGRABAAMBAQAAAAAAAAAAAAAAAgABERIh/9oACAEBAAEFAkjUpbWqcFn0vLn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECMTJB/9oACAEBAAY/AujUjSfKoaVGn//EABwQAAIBBQEAAAAAAAAAAAAAAAABESExUWGBkf/aAAgBAQABPyFLROi2tuYMBUkoFEWkalfuLGx6f//aAAwDAQACAAMAAAAQ9D//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBJ/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKjH/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAUSFh/9oACAEBAAE/EKF6sAS/L3RCSiqur7Hob0ugqhjn3IIVIYo1lZq1jK7/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 11 15 01",
            "title": "2024 11 15 01",
            "src": "/static/3a49e486a8225c616fff2c3c95a2ec4e/e5166/2024-11-15_01.jpg",
            "srcSet": ["/static/3a49e486a8225c616fff2c3c95a2ec4e/f93b5/2024-11-15_01.jpg 300w", "/static/3a49e486a8225c616fff2c3c95a2ec4e/b4294/2024-11-15_01.jpg 600w", "/static/3a49e486a8225c616fff2c3c95a2ec4e/e5166/2024-11-15_01.jpg 1200w", "/static/3a49e486a8225c616fff2c3c95a2ec4e/d9c39/2024-11-15_01.jpg 1800w", "/static/3a49e486a8225c616fff2c3c95a2ec4e/df51d/2024-11-15_01.jpg 2400w", "/static/3a49e486a8225c616fff2c3c95a2ec4e/71254/2024-11-15_01.jpg 4080w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`講演の様子`}</em></p>
    <p>{`今回は社外から講師をお招きするということもあり、勉強会へは 25 名ほどの参加となりました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d4ab307ac952b6833494394a7b16d4b1/71254/2024-11-15_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABbyd1hslh/8QAGxAAAwACAwAAAAAAAAAAAAAAAAECAxESIiP/2gAIAQEAAQUC3InLOhD9MlxJztn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAEQEyEjFRcf/aAAgBAQAGPwJZFixDk6ja8P/EABsQAAIDAQEBAAAAAAAAAAAAAAERACExUYGR/9oACAEBAAE/ISE0g8MFlD8lseINVlhwSldxCbPKP//aAAwDAQACAAMAAAAQdy//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABsQAQACAwEBAAAAAAAAAAAAAAEAIRFBUTHh/9oACAEBAAE/EEaAMoYqUoM4evk2mNpdz2Rl/M67cVMWQLnE3NwOqgn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 11 15 02",
            "title": "2024 11 15 02",
            "src": "/static/d4ab307ac952b6833494394a7b16d4b1/e5166/2024-11-15_02.jpg",
            "srcSet": ["/static/d4ab307ac952b6833494394a7b16d4b1/f93b5/2024-11-15_02.jpg 300w", "/static/d4ab307ac952b6833494394a7b16d4b1/b4294/2024-11-15_02.jpg 600w", "/static/d4ab307ac952b6833494394a7b16d4b1/e5166/2024-11-15_02.jpg 1200w", "/static/d4ab307ac952b6833494394a7b16d4b1/d9c39/2024-11-15_02.jpg 1800w", "/static/d4ab307ac952b6833494394a7b16d4b1/df51d/2024-11-15_02.jpg 2400w", "/static/d4ab307ac952b6833494394a7b16d4b1/71254/2024-11-15_02.jpg 4080w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`真剣に講演を聞くメンバー`}</em></p>
    <p>{`社内限定での勉強会ということもあり、ahomu さんからも、メドレーに合わせて濃いお話をしていただきました。`}</p>
    <ul>
      <li parentName="ul">{`歴史を踏まえた昨今のフロントエンドの技術的な変遷`}</li>
      <li parentName="ul">{`現代のフロントエンドの設計で重視しなければならないポイントと提供するプロダクトの特性の関係`}</li>
      <li parentName="ul">{`開発持続性を実現するための、開発環境について`}</li>
      <li parentName="ul">{`Web フロントエンド版 DX Criteria の成り立ちや目的を、弊社の状況も交じえながら解説`}</li>
      <li parentName="ul">{`Web フロントエンド版 DX Criteria のそれぞれの項目について、弊社の状況を踏まえて解説`}</li>
      <li parentName="ul">{`アセスメント後に、どのような整理をして改善していけばよいかの指針について`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a69d5bf2c22020fb958e9b09d317520/71254/2024-11-15_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4dBkUCA//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAERIDIv/aAAgBAQABBQIObbQGzHtSerX6af/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAARAjEBECL/2gAIAQEABj8Coa1zYpZHCj//xAAbEAADAAMBAQAAAAAAAAAAAAAAARExQXEhYf/aAAgBAQABPyF5FwUsK+jrghqq3RhmUpsnuLs//9oADAMBAAIAAwAAABCIH//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxDaj//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxDFf//EABsQAQADAQEBAQAAAAAAAAAAAAEAESExUXFB/9oACAEBAAE/EDRtTo7LNB0z8ik13ew2rO7jdxPI8Wmyi39BilK4FUvv5P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 11 15 03",
            "title": "2024 11 15 03",
            "src": "/static/2a69d5bf2c22020fb958e9b09d317520/e5166/2024-11-15_03.jpg",
            "srcSet": ["/static/2a69d5bf2c22020fb958e9b09d317520/f93b5/2024-11-15_03.jpg 300w", "/static/2a69d5bf2c22020fb958e9b09d317520/b4294/2024-11-15_03.jpg 600w", "/static/2a69d5bf2c22020fb958e9b09d317520/e5166/2024-11-15_03.jpg 1200w", "/static/2a69d5bf2c22020fb958e9b09d317520/d9c39/2024-11-15_03.jpg 1800w", "/static/2a69d5bf2c22020fb958e9b09d317520/df51d/2024-11-15_03.jpg 2400w", "/static/2a69d5bf2c22020fb958e9b09d317520/71254/2024-11-15_03.jpg 4080w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`佳境に入った講演の様子`}</em></p>
    <p>{`上述したものは内容の一例で、全てをお伝えできませんが参加者がより Web フロントエンド版 DX Criteria を「メドレーのプロダクト」ベースで考えられるような講義を行なっていただきました。`}</p>
    <p>{`参加者からも Web フロントエンド版 DX Criteria の内容を越えたフロントエンド技術についての質問なども飛び出して、大変盛況のうちに終了しました。`}</p>
    <p>{`改めてプロダクトのフロントエンド開発を事業発展にどのように繋げるかという視点で考えることができた、非常に有意義な時間となりました。`}</p>
    <p>{`多忙の中、今回の勉強会講師を快諾してくださった ahomu さんに大変感謝しております。ありがとうございました!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/772a70a7c5eeecf188809edd0e4f130e/d2602/2024-11-15_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFGg5F0Sj//xAAYEAEBAQEBAAAAAAAAAAAAAAABAgASIf/aAAgBAQABBQJJ3I6uBrugLJ9d/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHhAAAgEDBQAAAAAAAAAAAAAAABEBAiIxEBIhMnH/2gAIAQEABj8C7D3Murh+kxxlvS7J/8QAGxAAAwEBAAMAAAAAAAAAAAAAAREhAFFhcZH/2gAIAQEAAT8hKbCS5sJiXEh7LOwPIxYGH14ALn63/9oADAMBAAIAAwAAABAj/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qp//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVGBofD/2gAIAQEAAT8QaBlRvp8fchml5G/nL1iFsJ6ySmzR7cEnWBMHCYSN1gpANaCg4d5//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2024 11 15 04",
            "title": "2024 11 15 04",
            "src": "/static/772a70a7c5eeecf188809edd0e4f130e/e5166/2024-11-15_04.jpg",
            "srcSet": ["/static/772a70a7c5eeecf188809edd0e4f130e/f93b5/2024-11-15_04.jpg 300w", "/static/772a70a7c5eeecf188809edd0e4f130e/b4294/2024-11-15_04.jpg 600w", "/static/772a70a7c5eeecf188809edd0e4f130e/e5166/2024-11-15_04.jpg 1200w", "/static/772a70a7c5eeecf188809edd0e4f130e/d9c39/2024-11-15_04.jpg 1800w", "/static/772a70a7c5eeecf188809edd0e4f130e/df51d/2024-11-15_04.jpg 2400w", "/static/772a70a7c5eeecf188809edd0e4f130e/d2602/2024-11-15_04.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`ahomu さんと記念撮影`}</em></p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーではこれからも機会があれば、社内の開発者向けに外部講師を招聘して勉強会などを開いていく予定です。`}</p>
    <p>{`自分の技術を色々な角度から高めていきたい!と考えているエンジニア・デザイナーの方はぜひご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      